import { DateTime } from 'luxon'

export function getTimeStringFromDateTimeString(dateTime) {
  return DateTime.fromISO(dateTime).toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function getTimeStringFromDateObject(date) {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function getDateStringFromDateTimeString(dateTime) {
  return dateTime.substring(0, 10)
}

export function getTimestampFromDateObject(dateTime) {
  // convert to ISO and strip milliseconds (required by vuetify calendar)
  return DateTime.fromJSDate(dateTime).toISO().substring(0, 19)
}

export function getLocalTimeWithDate(date) {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_HUGE)
}

export function getLocalTimeWithDateAndTime(date) {
  return DateTime.fromJSDate(date)
    .setLocale('de')
    .toFormat('EEEE, dd.MM.yyyy HH:mm')
}

export function getGermanDateFormat(date) {
  let options = {
    dateStyle: 'full',
    timeStyle: 'short',
  }
  return new Date(date).toLocaleString('de-DE', options)
}

export function getGermanDateFormatWithoutTime(date) {
  let options = {
    dateStyle: 'full',
  }
  return new Date(date).toLocaleString('de-DE', options)
}

export function getShortGermanDate(date) {
  let options = {
    dateStyle: 'short',
  }
  return new Date(date).toLocaleString('de-DE', options)
}

export function getShortGermanDateWithoutTime(date) {
  const dateTime = DateTime.fromISO(date)

  return dateTime.setLocale('de').toFormat('EEE, D')
}

export function getShortGermanDateWithNormalYear(date) {
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return new Date(date).toLocaleString('de-DE', options)
}

export function getShortGermanDateWithNumericYear(date) {
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
  }
  return new Date(date).toLocaleString('de-DE', options)
}

export function getDurationSplit(duration) {
  let split = {
    minutes: duration % 60,
    days: Math.floor(duration / 1440),
    hours: Math.floor((duration % 1440) / 60),
  }
  return split
}

export function getDateTimeStringFromDate(date) {
  let dateString = DateTime.fromJSDate(date)
    .setLocale('de')
    .toFormat('yyyy-MM-dd')

  let timeString = DateTime.fromJSDate(date)
    .setLocale('de')
    .toFormat('HH:mm:ss')

  return `${dateString}T${timeString}`
}

export function getCustomDateTimeStringFromDate(date) {
  let dateString = DateTime.fromJSDate(date)
    .setLocale('de')
    .toFormat('dd.MM.yyyy')

  let timeString = DateTime.fromJSDate(date).setLocale('de').toFormat('HH:mm')

  return `${dateString} ${timeString}`
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000)
}

export function getMinutesFromDuration(duration) {
  return (
    parseInt(duration.minutes) +
    parseInt(duration.hours) * 60 +
    parseInt(duration.days) * 24 * 60
  )
}

export function getMinutesDifference(start, end) {
  return DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start), 'minutes')
    .minutes
}

export function getDaysHoursMinutesFromDateDifference(start, end) {
  let diff = DateTime.fromJSDate(end)
    .diff(DateTime.fromJSDate(start), ['days', 'hours', 'minutes', 'seconds'])
    .toObject()

  return {
    days: diff.days,
    hours: diff.hours,
    minutes: diff.minutes,
  }
}

export function addTimeToDate(origDate, days, hours, minutes) {
  return DateTime.fromJSDate(new Date(origDate))
    .plus({
      days,
      hours,
      minutes,
    })
    .toJSDate()
}

export function addMinutesToDateTime(dt, minutes) {
  return new Date(dt.getTime() + minutes * 60000)
}

export function daysBetween(start, end) {
  return getDaysHoursMinutesFromDateDifference(start, end).days
}

export function isBeforeToday(date) {
  let d = new Date(date)
  let today = new Date()
  return d <= today
}

export function isValidDate(day, month, year) {
  day = parseInt(day)
  month = parseInt(month)
  year = parseInt(year)

  if (year < 0) return false
  if (year < 1000) return false
  if (month < 1 || month > 12) return false
  if (day < 1) return false

  // Check for February and leap years
  if (month === 2) {
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      // Leap year
      return day <= 29
    } else {
      // Non-leap year
      return day <= 28
    }
  }

  // Check for months with 30 days
  if ([4, 6, 9, 11].includes(month)) {
    return day <= 30
  }

  // All other months have 31 days
  return day <= 31
}

export function isValidTime(hour, minute) {
  hour = parseInt(hour)
  minute = parseInt(minute)

  if (isNaN(hour) || isNaN(minute)) return false
  if (hour < 0 || hour > 23) return false
  if (minute < 0 || minute > 59) return false

  return true
}

export function formatDate(dateTime) {
  if (!dateTime) return '-'

  let [date, time] = dateTime.split('T')

  const [year, month, day] = date.split('-')
  if (time) {
    const [hour, minute] = time.split(':')
    return `${day}.${month}.${year} ${hour}:${minute}`
  } else {
    return `${day}.${month}.${year}`
  }
}

export function getWeekNumber(date) {
  return DateTime.fromJSDate(date).weekNumber
}
